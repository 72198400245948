import { getGames, IGameConfig } from "@/api";
import { PrimaryButton } from "@/components";
import { setSessionStorage } from "@/helpers";
import { GameLayout } from "@/layouts";
import { navigate } from "gatsby";
import React, { ChangeEvent, useEffect, useState } from "react";

export default function GamePicker() {
	const [games, setGames] = useState<IGameConfig[]>([]);
	useEffect(() => {
		getGames().then((response) => setGames(response));
	}, []);
	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const gameId = e.currentTarget["gameId"].value;
		if (!gameId) return;
		setSessionStorage("zazar-game-id", gameId);
		navigate("/game");
	};
	return (
		<GameLayout navbar={true}>
			<form onSubmit={onSubmit}>
				<p>Select a game</p>
				<select name="gameId">
					{games.map((g) => (
						<option key={g.pk} value={g.pk}>
							{g.title} - {g.pk}
						</option>
					))}
				</select>
				<PrimaryButton type="submit" disabled={!games || !games.length}>
					Go
				</PrimaryButton>
			</form>
		</GameLayout>
	);
}
